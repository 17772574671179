.vendors-container {
  min-height: 100vh;
}
.vendor-header {
  display: flex;
  align-items: center;
}
.select-w-100 {
  width: 100%;
}
.scroll-to-top-btn {
  margin-top: 16px;
  background-color: red;
  color: white;
}
.margin-l {
  margin-left: 1em;
}
